import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('body').on('change', '#external_member_gender', function(event) {
      if ($("#external_member_gender").val() == "Firma") {
        $(".company-field").addClass('active');
      }
      else {
        $(".company-field").removeClass("active");
        $("#external_member_companyname").val('');
      }
    });
  }
}
